.MobileHome > .kuva{
    margin: 0 0 0 2rem;
    max-width: 70vw;
    max-height: 30rem;
    margin-bottom: 0rem;
    transition: opacity 1s;
}

.MobileParagraph{
    width: 60%;
    margin: 4rem 0rem 4rem 5rem;
    color: rgb(46, 46, 46);
}

.MobileHome{
    padding-bottom: 5rem;
    border-left: solid rgba(253, 0, 0, 0.685);
}
.Name{
    padding-right: 1rem;
}

.HomeText{
    display: flex;
    flex-direction: column;
}
.Name{
    font-size: 1.4em;
    font-family: 'Roboto', sans-serif;
    margin: 0 0 1rem 0;
}
.para{
    font-size: 1.1em;
    height: fit-content;
    padding: 0 0 2em 0;
    
}
.Link{
    align-self: flex-end;
    margin: 0 0 0.5rem 0;
    font-size: 1.1em;
    z-index: 100
}
.Link:hover{
    color: black;
}
.ParagraphScroll{
    height: 20rem;
    margin: 2rem 0 0 0;
}
@media (max-width: 800px){
    .HomeText{
        font-size: 0.9rem;
        display: inherit;
    }
    .Name{
        font-size: 1.1rem;
        position: fixed;
        writing-mode: vertical-rl;
        top: 5rem;
        right: 0rem;

    }
}
.HomeText{
    position: fixed;
    right: 0;
    width: 25%;
    padding: 7rem 8rem 0 0;
}


.ImageScroll{
    display: flex;
    flex-direction: column;
    width: 75vw;
    align-items: center;
} 

.kuva{
    max-width: 70%;
    margin-bottom: 10rem;
    transition: opacity 1s;
}
.kuva0{

    margin: 0 0 10rem 0;

}
.kuva2{
    margin: 0;
    align-self: flex-start;
}



.backlink{
    margin: 1rem 1rem;
    position: fixed;
}
.Timeline{
    top: 7rem;
}
.Wall{
    margin: 0 10rem 0 15%;
    padding-top: 4rem;
}
.row{
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    
}
.imageFrame{
    background-color: gray;
}


.PreviewLayer{
    background-color: rgba(165, 165, 165, 0.877);
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.Credits{
    margin: 2rem 0 2rem 15%;
}
.CreditsHeader{
    font-size: 1rem;
}
.CreditList{
    list-style-type: none;
    font-size: 0.8rem;
}

@media (max-width: 800px){
    .Wall{
        margin: 0 5rem 0 0;
    }
}
.Preview{
    display: flex;
    flex-direction: column;
    justify-content: space-around;

}
.Cross{
    position: absolute;
    height: 4rem;
    width: 4rem;
    top: 4rem;
    right: 20rem;
    cursor: pointer
}
.PreviewFrame{

    max-height: 90vh;
    max-width: 90vw;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Arrow{
    position: relative;
    top: 45%;
}
.LeftArrow{
    position: absolute;
    left: 10%;
    transform: translateY(4rem);
    cursor: pointer;
}
.RightArrow{
    position: absolute;
    right: 10%;
    transform: translateY(4rem);
    cursor: pointer;
}
.previewImage{
    max-height: 85vh;
    max-width: 85vw;
    transition: opacity 1s;
}

@media (max-width: 800px){
    .Arrow{
        width: 1.5rem;
        padding: 1rem;
        top: 30%;
    }
    .RightArrow{
        right: 0;
        padding-right: 0.1rem;
    }
    .LeftArrow{
        left: 0;
        padding-left: 0.1rem
    }
    .Cross{
        height: 2rem;
        width: 2rem;
        top: 1rem;
        right: 1rem;
    }
}
.Timeline{
    position: fixed;
    left: 3rem;
    height: 80vh;
    display: flex;
    justify-content: flex-start;
    
}
.meter{
    position: relative;
    margin: 0 1rem 0 0;
    width:2px;
    background-color: rgb(255, 234, 234);
    border-radius: 5px;
}
.TimeFork{
    position: relative;
    background-color: rgb(158, 0, 0);
    width:2px;
    border-radius: 5px;

}
.years{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.year {
    color: inherit;
}

@media (max-width: 800px){
    .Timeline{
        left:inherit;
        right: 1rem;

    }
}
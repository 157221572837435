.NavList{
    background-color: white;
    position: fixed;
}

.upper-container{
    display: flex;
    align-items: center;
}
.naw-backlink{
    margin: 0 0 0 1rem;
    display: flex;
    align-items: center;
}
.naw-backlink > svg {
    display:inline-block;
}
.naw-home{
    padding: 0 0 0 1rem;
    font-size: 1.5rem;
}
.naw-link{
    margin: 0 0 0 1rem;
    display: flex;
    align-items: center;
}
.naw-link > h4{
    padding: 0 1rem 0 0;
    font-size: 1.5rem;
}

.link-container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 50% 0 0 0rem;
}

@media (max-height: 550px){
    .link-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 5vh 0 0 0rem;
    }
}
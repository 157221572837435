.ImageFrame {
    margin: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.ImageFrame > img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
}
